.header-logo {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
  
  .header-title {
    color: white;
    text-decoration: none;
  }
  
  .custom-list-item {
    color: white;
    line-height: 48px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .custom-list-item:hover {
    background-color: #f0f0f0;
  }

  .menu-items {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 5px 10px; /* 여백 추가로 버튼 모양 완성 */
    border-radius: 4px; /* 둥근 모서리로 버튼 스타일 */
    white-space: nowrap; /* 텍스트 줄바꿈 방지 */
  }